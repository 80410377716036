<template>
	<div>
		<template>
      <v-data-table
        :headers="tables.organizedCompetitions.headers"
				:items="organizedCompetitions"
				sort-by="date"
				sort-desc
      >
	      <template v-slot:top>
					<v-select
						:items="[{text: 'Rendezés alatt', value: 1},{text: 'Megrendezve', value: 0}]"
						item-text="text"
						item-value="value"
						v-model="isUnderOrganization"
						label="Versenyállapot"
						prepend-inner-icon="fa-check"
						@change="getCompetitions()"
					/>
					<v-tooltip top>
						Hozzáadás
						<template v-slot:activator="{on, attrs}">
							<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialog.show=true"><v-icon>fa-plus</v-icon></v-btn>
						</template>
					</v-tooltip>
	      </template>

				<template v-slot:item.level="{item}">
					{{competitionLevelNames[item.level]}}
				</template>
				<template v-slot:item.status="{item}">
					{{competitionStatusNames[item.status]}}
				</template>
				<template v-slot:item.view="{item}">
					<v-btn icon :to="{name: 'competitionOrganizer', params: {id: item.id}}"><v-icon>fa-eye</v-icon></v-btn>
				</template>

      </v-data-table>
		  <v-row justify="center">
		    <v-dialog
		      v-model="dialog.show"
		      persistent
		      max-width="600px"
		    >

		      <v-card>
		        <v-card-title>
		          <span class="headline">Új verseny</span>
		        </v-card-title>
		        <v-card-text>
		          <v-container>
								<v-text-field
									prepend-inner-icon="fa-trophy"
									label="Verseny"
									v-model="dialog.competition.name"
									rounded
									outlined
								/>
								<v-textarea
									prepend-inner-icon="fa-info-circle"
									label="Leírás"
									v-model="dialog.competition.description"
									rounded
									outlined
								/>
								<v-text-field
									prepend-inner-icon="fa-map-marker"
									label="Helyszín"
									v-model="dialog.competition.place"
									rounded
									outlined
								/>
								<v-select
									prepend-inner-icon="fa-globe-europe"
									label="Szint"
									v-model="dialog.competition.level"
									:items="competitionLevels"
									item-text="name"
									item-value="level"
									rounded
									outlined
								/>
								<v-menu
					        v-model="dialog.date.menus.date"
					        :close-on-content-click="false"
					        :nudge-right="40"
					        transition="scale-transition"
					        offset-y
					        min-width="auto"
					      >
					        <template v-slot:activator="{ on, attrs }">
					          <v-text-field
					            v-model="dialog.date.date"
					            label="Időpont"
					            prepend-inner-icon="fa-calendar"
					            readonly
					            v-bind="attrs"
					            v-on="on"
											rounded
											outlined
					          />
					        </template>
					        <v-date-picker
					          v-model="dialog.date.date"
										:first-day-of-week="1"
					          @input="dialog.date.menus.date = false"
					        />
					      </v-menu>
								<v-menu
					        v-model="dialog.date.menus.time"
					        :close-on-content-click="false"
					        :nudge-right="40"
					        transition="scale-transition"
					        offset-y
					        min-width="auto"
					      >
					        <template v-slot:activator="{ on, attrs }">
					          <v-text-field
					            v-model="dialog.date.time"
					            label="Időpont"
					            prepend-inner-icon="fa-clock"
					            readonly
					            v-bind="attrs"
					            v-on="on"
											rounded
											outlined
					          />
					        </template>
					        <v-time-picker
										v-if="dialog.date.menus.time"
					          v-model="dialog.date.time"
					          @click:minute="dialog.date.menus.time = false"
										format="24hr"
					        />
					      </v-menu>
								<v-menu
					        v-model="dialog.applicationDeadline.menus.date"
					        :close-on-content-click="false"
					        :nudge-right="40"
					        transition="scale-transition"
					        offset-y
					        min-width="auto"
					      >
					        <template v-slot:activator="{ on, attrs }">
					          <v-text-field
					            v-model="dialog.applicationDeadline.date"
					            label="Nevezési határidő"
					            prepend-inner-icon="fa-calendar"
					            readonly
					            v-bind="attrs"
					            v-on="on"
											rounded
											outlined
					          />
					        </template>
					        <v-date-picker
					          v-model="dialog.applicationDeadline.date"
										:first-day-of-week="1"
					          @input="dialog.applicationDeadline.menus.date = false"
					        />
					      </v-menu>
								<v-menu
					        v-model="dialog.applicationDeadline.menus.time"
					        :close-on-content-click="false"
					        :nudge-right="40"
					        transition="scale-transition"
					        offset-y
					        min-width="auto"
					      >
					        <template v-slot:activator="{ on, attrs }">
					          <v-text-field
					            v-model="dialog.applicationDeadline.time"
					            label="Nevezési határidő"
					            prepend-inner-icon="fa-clock"
					            readonly
					            v-bind="attrs"
					            v-on="on"
											rounded
											outlined
					          />
					        </template>
					        <v-time-picker
										v-if="dialog.applicationDeadline.menus.time"
					          v-model="dialog.applicationDeadline.time"
					          @click:minute="dialog.applicationDeadline.menus.time = false"
										format="24hr"
					        />
					      </v-menu>
		          </v-container>
		        </v-card-text>
		        <v-card-actions>
		          <v-spacer></v-spacer>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="dialog.show = false"
		          >
		            Mégsem
		          </v-btn>
		          <v-btn
		            color="blue darken-1"
		            text
		            @click="create()"
								v-if="dialog.competition.name&&dialog.competition.description&&dialog.competition.place&&dialog.date.date&&dialog.date.time&&!isNaN(dialog.competition.level)"
		          >
		            Hozzáadás
		          </v-btn>
		        </v-card-actions>
		      </v-card>
	    </v-dialog>
	  </v-row>

	</template>
	</div>
</template>

<script>
	import router from '../router';
	import competitionLevelNames from '../plugins/competitionLevelNames';
	import roles_CompetitionLevels from '../plugins/roles-CompetitionLevels'
	import competitionStatusNames from '../plugins/competitionStatusNames'
	export default {
		data: function(){
			return {
				isUnderOrganization: 1,
				competitionLevelNames,
				competitionStatusNames,
				organizedCompetitions: [],
        tables: {
          organizedCompetitions: {
            headers: [
              {
                text: "Verseny",
                align: "center",
                sortable: true,
                filterable: true,
                value: "name"
              },
              {
                text: "Leírás",
                align: "center",
                sortable: false,
                filterable: true,
                value: "description"
              },
              {
                text: "Időpont",
                align: "center",
                sortable: true,
                filterable: true,
                value: "date"
              },
							{
                text: "Helyszín",
                align: "center",
                sortable: false,
                filterable: true,
                value: "place"
              },
							{
								text: "Szint",
								align: "center",
								sortable: false,
								filterable: false,
								value: "level"
							},
							{
								text: "Állapot",
								align: "center",
								sortable: false,
								filterable: false,
								value: "status"
							},
							{
								text: "Megtekintés",
								align: "center",
								sortable: false,
								filterable: false,
								value: "view"
							}
            ]
          }
        },
        dialog: {
          show: false,
					competition: {
						name: '',
						description: '',
						place: '',
						level: 0
					},
					date: {
						date: this.addMonths(new Date(), 2).toISOString().substring(0,10),
						time: '13:00',
						menus: {
							date: false,
							time: false
						}
					},
					applicationDeadline: {
						date: this.addMonths(new Date(), 1).toISOString().substring(0,10),
						time: '23:59',
						menus: {
							date: false,
							time: false
						}
					}
        }
      }
		},
		computed: {
			competitionLevels() {
				return roles_CompetitionLevels[this.$store.getters.userRole].map(cL => {
					return {
						level: cL,
						name: competitionLevelNames[cL]
					}
				})
			}
		},
		watch: {

		},
		methods: {
			create: function() {
				this.axios({url: "competition/", method: "POST", data: {competition: {...this.dialog.competition, date: this.dialog.date.date+" "+this.dialog.date.time, applicationDeadline: this.dialog.applicationDeadline.date+" "+this.dialog.applicationDeadline.time}}}).then((response) => {
					if(response.data.success) {
						this.organizedCompetitions.push({...this.dialog.competition, date: this.dialog.date.date+" "+this.dialog.date.time, applicationDeadline: this.dialog.applicationDeadline.date+" "+this.dialog.applicationDeadline.time, id: response.data.data.id, status: -1});
						this.dialog.show=false;
						this.dialog.competition = { name: '', description: '', place: ''}
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')
					}
				})
			},
			getCompetitions: function() {
				this.axios({url: `competition/list/organizer/${this.isUnderOrganization}`, method: "GET"}).then((response) => {
	        if(response.data.success) {
						this.organizedCompetitions = response.data.data.organizedCompetitions;
	        }
				})
			}
		},
		mounted(){
			this.getCompetitions();
		}
	}
</script>
