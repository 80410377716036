var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[_c('v-data-table',{attrs:{"headers":_vm.tables.organizedCompetitions.headers,"items":_vm.organizedCompetitions,"sort-by":"date","sort-desc":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-select',{attrs:{"items":[{text: 'Rendezés alatt', value: 1},{text: 'Megrendezve', value: 0}],"item-text":"text","item-value":"value","label":"Versenyállapot","prepend-inner-icon":"fa-check"},on:{"change":function($event){return _vm.getCompetitions()}},model:{value:(_vm.isUnderOrganization),callback:function ($$v) {_vm.isUnderOrganization=$$v},expression:"isUnderOrganization"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"success"},on:{"click":function($event){_vm.dialog.show=true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-plus")])],1)]}}])},[_vm._v(" Hozzáadás ")])]},proxy:true},{key:"item.level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.competitionLevelNames[item.level])+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.competitionStatusNames[item.status])+" ")]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name: 'competitionOrganizer', params: {id: item.id}}}},[_c('v-icon',[_vm._v("fa-eye")])],1)]}}])}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Új verseny")])]),_c('v-card-text',[_c('v-container',[_c('v-text-field',{attrs:{"prepend-inner-icon":"fa-trophy","label":"Verseny","rounded":"","outlined":""},model:{value:(_vm.dialog.competition.name),callback:function ($$v) {_vm.$set(_vm.dialog.competition, "name", $$v)},expression:"dialog.competition.name"}}),_c('v-textarea',{attrs:{"prepend-inner-icon":"fa-info-circle","label":"Leírás","rounded":"","outlined":""},model:{value:(_vm.dialog.competition.description),callback:function ($$v) {_vm.$set(_vm.dialog.competition, "description", $$v)},expression:"dialog.competition.description"}}),_c('v-text-field',{attrs:{"prepend-inner-icon":"fa-map-marker","label":"Helyszín","rounded":"","outlined":""},model:{value:(_vm.dialog.competition.place),callback:function ($$v) {_vm.$set(_vm.dialog.competition, "place", $$v)},expression:"dialog.competition.place"}}),_c('v-select',{attrs:{"prepend-inner-icon":"fa-globe-europe","label":"Szint","items":_vm.competitionLevels,"item-text":"name","item-value":"level","rounded":"","outlined":""},model:{value:(_vm.dialog.competition.level),callback:function ($$v) {_vm.$set(_vm.dialog.competition, "level", $$v)},expression:"dialog.competition.level"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Időpont","prepend-inner-icon":"fa-calendar","readonly":"","rounded":"","outlined":""},model:{value:(_vm.dialog.date.date),callback:function ($$v) {_vm.$set(_vm.dialog.date, "date", $$v)},expression:"dialog.date.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.date.menus.date),callback:function ($$v) {_vm.$set(_vm.dialog.date.menus, "date", $$v)},expression:"dialog.date.menus.date"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.dialog.date.menus.date = false}},model:{value:(_vm.dialog.date.date),callback:function ($$v) {_vm.$set(_vm.dialog.date, "date", $$v)},expression:"dialog.date.date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Időpont","prepend-inner-icon":"fa-clock","readonly":"","rounded":"","outlined":""},model:{value:(_vm.dialog.date.time),callback:function ($$v) {_vm.$set(_vm.dialog.date, "time", $$v)},expression:"dialog.date.time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.date.menus.time),callback:function ($$v) {_vm.$set(_vm.dialog.date.menus, "time", $$v)},expression:"dialog.date.menus.time"}},[(_vm.dialog.date.menus.time)?_c('v-time-picker',{attrs:{"format":"24hr"},on:{"click:minute":function($event){_vm.dialog.date.menus.time = false}},model:{value:(_vm.dialog.date.time),callback:function ($$v) {_vm.$set(_vm.dialog.date, "time", $$v)},expression:"dialog.date.time"}}):_vm._e()],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Nevezési határidő","prepend-inner-icon":"fa-calendar","readonly":"","rounded":"","outlined":""},model:{value:(_vm.dialog.applicationDeadline.date),callback:function ($$v) {_vm.$set(_vm.dialog.applicationDeadline, "date", $$v)},expression:"dialog.applicationDeadline.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.applicationDeadline.menus.date),callback:function ($$v) {_vm.$set(_vm.dialog.applicationDeadline.menus, "date", $$v)},expression:"dialog.applicationDeadline.menus.date"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1},on:{"input":function($event){_vm.dialog.applicationDeadline.menus.date = false}},model:{value:(_vm.dialog.applicationDeadline.date),callback:function ($$v) {_vm.$set(_vm.dialog.applicationDeadline, "date", $$v)},expression:"dialog.applicationDeadline.date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Nevezési határidő","prepend-inner-icon":"fa-clock","readonly":"","rounded":"","outlined":""},model:{value:(_vm.dialog.applicationDeadline.time),callback:function ($$v) {_vm.$set(_vm.dialog.applicationDeadline, "time", $$v)},expression:"dialog.applicationDeadline.time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dialog.applicationDeadline.menus.time),callback:function ($$v) {_vm.$set(_vm.dialog.applicationDeadline.menus, "time", $$v)},expression:"dialog.applicationDeadline.menus.time"}},[(_vm.dialog.applicationDeadline.menus.time)?_c('v-time-picker',{attrs:{"format":"24hr"},on:{"click:minute":function($event){_vm.dialog.applicationDeadline.menus.time = false}},model:{value:(_vm.dialog.applicationDeadline.time),callback:function ($$v) {_vm.$set(_vm.dialog.applicationDeadline, "time", $$v)},expression:"dialog.applicationDeadline.time"}}):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog.show = false}}},[_vm._v(" Mégsem ")]),(_vm.dialog.competition.name&&_vm.dialog.competition.description&&_vm.dialog.competition.place&&_vm.dialog.date.date&&_vm.dialog.date.time&&!isNaN(_vm.dialog.competition.level))?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Hozzáadás ")]):_vm._e()],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }